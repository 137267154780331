<template>
  <div class="app-container">
    <CrudTable entity="DeliveryAppUser" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [{ field: 'code', label: 'login.code' }, 'name']
    };
  }
};
</script>

<style scoped></style>
